
export const isBall = (ballPath, holeBounds) => {//function to determine if given path is a ball

  //how to determine badd balls
  // 1. big radius
  // 2. no y pos below hole bottom

  let lowestY = 0;
  let max = 0;
  for (let i = 0; i < ballPath.length; i++) {//get min and max radius
    let rad = ballPath[i][2];
    if (rad > max){
      max = rad;
    }
    if (ballPath[i][1] > lowestY){
      lowestY = ballPath[i][1];
    }
  }

  const holeBottom = holeBounds[1] + (holeBounds[2] / 2);

  if (lowestY < holeBottom){
    return false;
  }

  if (max > 60){//check if max between 5 and 30
    return false;
  }
  if (max < 10){
    return false;
  }

  return true;

}

export const wasRollUnder = (ballPath, holeBounds) => {//see if path that rolled under hole after miss

  let c = 0;
  let ct = ballPath.length;
  const holeCenterX = holeBounds[0];
  const holeCenterY = holeBounds[1];
  const holeRadius = (holeBounds[2] / 2) + 5;

  //see if path at least 10 points, if not set to lower number
  if (ballPath.length < 10){
    ct = ballPath.length; }

  for (let i = ballPath.length - 1; i >= ballPath.length - ct ; i--) {

    const a = holeCenterX - ballPath[i][0];// a
    const b = holeCenterY - ballPath[i][1];// b

    const rad = Math.sqrt( (a*a) + (b*b) );

    if (rad < holeRadius){//this is the first within the radius of the hole
      c++;
    }
    else{
      break;
    }
  }
  //console.log("c:" + c + " vs array length:", ballPath.length);
  if (c == ballPath.length){//if all points on path are in hole
    return true;
  }
  return false;
  //return c;
}
export const getBallSpeed = (ballPath, holeBounds) => {//function to determine if given path is a ball

  let speed = 0;
  let startY = 500;
  let ct = 0;
  let tot = ballPath.length;
  //calc bottom y
  const holeCenterY = holeBounds[1];
  const holeRadius = holeBounds[2] / 2;
  let holeBottom = holeCenterY + holeRadius;

  //loop through points and compare to previous y
  for (let i = 0; i < tot; i++) {

    const y = ballPath[i][1];//y coord of ball path

    if ((y <= startY) && (y >= holeBottom)){//this means ball is moving up ramp and before hole

      if (y == startY){//not duplicate
        //console.log("duplicate y:", y);
      }
      else {
        if (i > 0){//calculate difference and add to ct
          ct += startY - y;
        }
        startY = y;
      }
    }
    else {
      tot = i;
      break;
    }
  }
  speed = ct / tot;
  //console.log("speed:", speed);
  return speed.toFixed(2);
}
export const getDuplicates = (ballPath) => {

  let duplicates = [];

  for (let i = 1; i < ballPath.length; i++) {//get min and max radius

    if ( (ballPath[i][1] == ballPath[i-1][1]) && (ballPath[i][0] == ballPath[i-1][0]) && (ballPath[i][2] == ballPath[i-1][2]) ){
      duplicates.push(ballPath[i][0] + "," + ballPath[i][1] + "," + ballPath[i][2]);
    }

  }

  return duplicates;

}
export const getMakeProbability = (ballPath, holeBounds) => {//get probability that the given path and bounds of hole was a make

  let c = 0;
  let ct = 10;
  const holeCenterX = holeBounds[0];
  const holeCenterY = holeBounds[1];
  const holeRadius = (holeBounds[2] / 2) + 10;

  //see if path at least 10 points, if not set to lower number
  if (ballPath.length < 10){
    ct = ballPath.length; }

  for (let i = ballPath.length - 1; i >= ballPath.length - ct ; i--) {//go through last 10 points in path

    const a = holeCenterX - ballPath[i][0];// a
    const b = holeCenterY - ballPath[i][1];// b

    const rad = Math.sqrt( (a*a) + (b*b) );

    if (rad < holeRadius){//this is the first within the radius of the hole
      c++;
    }
    else{
      break;
    }
  }
  return c;
}
export const getScore = (playerPutts) => {
  let score = 0;
  for (let i = 0; i < playerPutts.length ; i++) {
    score += playerPutts[i];
  }
  return score;
}
export const getHoleEntry = (ballPath, holeBounds) => {

  const holeCenterX = holeBounds[0];
  const holeCenterY = holeBounds[1];
  const holeRadius = holeBounds[2] / 2;
  //let holeMade = false;

  for (let i = 0; i < ballPath.length; i++) {
    let ballX = ballPath[i][0];
    let ballY = ballPath[i][1];

    //get the first point that is within the radius of hole (60 pixels)
    const a = holeCenterX - ballX;// a
    const b = holeCenterY - ballY;// b

    const rad = Math.sqrt( (a*a) + (b*b) );

    if (rad < holeRadius){//this is the first within the radius of the hole
      //see which quadrant of hole
      let xQuad = "RIGHT";
      let yQuad = "FRONT"
      if (ballX < holeCenterX)
        xQuad = "LEFT";
      if (ballY < holeCenterY)
        yQuad = "BACK";

      const quad = yQuad + " " + xQuad;
      const rad = Math.atan(a/b);//calculate degress from center
      let deg = Math.round(rad * (180 / Math.PI));

      if (quad == "BACK LEFT"){ //ball entered into back half of hole
        //negative degrees
        deg = -90 - (90 - deg);
      }
      else if (quad == "BACK RIGHT"){ //ball entered into back half of hole
        //positive degrees -25
        deg = 90 + (90 + deg);
      }

      return [ballX, ballY, deg, quad];
    }
  }
  //if not entry point found, return 0
  return [0,0,0,0];
}
export const getMissResult = (ballPath, holeBounds) => {

  const holeCenterX = holeBounds[0];
  const holeCenterY = holeBounds[1];
  const holeRadius = holeBounds[2] / 2;
  const holeBottom = holeCenterY + holeRadius;

  let peakY = 500;
  let peakCoords = 0;

  for (let i = 0; i < ballPath.length; i++) {
    let ballX = ballPath[i][0];
    let ballY = ballPath[i][1];

    if (ballY < peakY){
      peakY = ballY;
      peakCoords = ballPath[i];
    }
  }

  let retText = "LEFT";
  if (peakCoords[0] > holeCenterX){
    retText = "RIGHT";
  }

  //if (peakCoords[1] > holeBottom){
  //  retText += " SHORT";
  //}

  return retText;
}
export const getTeeEntry = (distance, tee) => {

  let perfAng = 0;
  let a = 0;
  if (tee == 1){
    a = -8;
  }
  else if (tee == 3){
    a = 8;
  }
  const b = distance * 12;
  const perfRad = Math.atan(a/b);
  perfAng = Math.round(perfRad * (180 / Math.PI)) * 1.1;
  //const p = Math.round((perfAng + Number.EPSILON) * 100) / 100;
  return perfAng;
}
export const getRandomPutts = (num) => {//get array of random putts

  let newArr = [];

  for (var i=0;i<num;i++){
    const id = Math.floor(Math.random() * 10000000);
    const distance = Math.floor(Math.random() * 9) + 3;
    const tee = Math.floor(Math.random() * 3)+1;
    newArr.push({id:id, distance:distance, tee:tee});
  }
  return newArr;

}
export const getEntryDescription = (e) => {

  let c = "CENTER CUT";

  if ( (e > -18) && (e < 18) ){
    c = "CENTER CUT"
  }
  else if ((e > 17) && (e < 54)){
    c = "RIGHT CENTER";
  }
  else if ((e > 53) && (e < 90)){
    c = "RIGHT SIDE";
  }
  else if ((e < -17) && (e > -54)){
    c = "LEFT CENTER";
  }
  else if ((e < -53) && (e > -90)){
    c = "LEFT CENTER";
  }
  else {
    c = "BACK DOOR";
  }

  return c;
}
export const getSpeedDescription = (speed, perfectSpeed) => {

  const speedDiff = speed - perfectSpeed;
  let s = "PERFECT";
  if ( (speedDiff >= -1) && (speedDiff <= 1) ){
    s = "PERFECT";
  }
  else if ( (speedDiff >= -2) && (speedDiff <= -1) ){
    s = "LITTLE SLOW";
  }
  else if ( (speedDiff <= -2) ){
    s = "TOO SLOW";
  }
  else if ( (speedDiff >= 1) && (speedDiff <= 2) ){
    s = "LITTLE FAST";
  }
  else if (speedDiff >=2){
    s = "TOO FAST";
  }
  return s;
}
export const getPuttScore = (angDiff, made, speed, perfectSpeed) => {

  let entryScore = 50;
  const degOff = Math.abs(angDiff);

  if (degOff > 0){//if not perfect
    const diffFactor = 1 - (degOff / 90);
    entryScore = entryScore * diffFactor;
  }

  if (made == 0){//if missed putt
    entryScore = 0;
  }

  let speedScore = 50;

  const speedOff = Math.abs(speed - perfectSpeed);//diff times 4
  if (speedOff > 0){//if not perfect
    const speedFactor = 1 - (speedOff / 3);
    speedScore = speedScore * speedFactor;
  }

  let score = (entryScore + speedScore);
  return score;
}
export const getPuttGrade = (score) => {

  let grade = "";

  if (score < 20){
    grade = "F";
  }
  else if ( (score >= 20) && (score < 40)){
    grade = "D";
    if (score > 35){
      grade += "+";
    }
  }
  else if ( (score >= 40) && (score < 60)){
    grade = "C";
    if (score > 55){
      grade += "+";
    }
  }
  else if ( (score >= 60) && (score < 80)){
    grade = "B";
    if (score > 75){
      grade += "+";
    }
  }
  else if (score >= 80){
    grade = "A";
    if (score > 95){
      grade += "+";
    }
  }
  return grade;
}
