import React from 'react';
import Image from 'react-bootstrap/Image';
import { Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function UserRow(props) {


  const getPlaceText = (place) => {
    let str = place;
    if (place == 1)
      str += "st";
    if (place == 2)
      str += "nd";
    if (place == 3)
      str += "rd";
    else if (place > 3)
      str += "th";
    return str;
  }
  const renderAvatar = (user) => {
    if (user.photoURL){
      return(
        <div className="avatar">
          <Image roundedCircle src={user.photoURL} width="40" height="40" alt="Photo" />
        </div>
      );
    }

    else {

      const i = user.displayName.substring(0,1);
      return(
        <div className="avatar"> {i} </div>
      );

    }
  }




  const u = props.user;

  if (u){

    return(

      <div className="prow">

        {renderAvatar(u)}

        <div style={{marginLeft:'10px'}} >

          <div className="displayName">{u.displayName}</div>

          <div className="homeTown">{u.homeTown}</div>

        </div>

      </div>

    );

  }





}
