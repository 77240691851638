import Image from 'react-bootstrap/Image';
import { lightGreen } from '../../constants/Colors';

export default function LeaderRow(props) {

  const u = JSON.parse(props.leader.userName);

  let dot = {
    position: 'relative',
    borderColor:'#fff',
    borderStyle:'solid',
    color:'#fff',
    borderWidth: 1,
    left:10,
    top:-15,
    width:25,
    height:25,
    borderRadius:12.5,
    backgroundColor:lightGreen,
    alignItems:'center',
    justifyContent:'center',
    fontWeight:'bold',
    fontSize:'9pt',
    textAlign:'center',
    display:'flex'
  };

  const renderAvatar = (user) => {
    if (user.photoURL){
      return(
        <div className="avatar">
          <Image roundedCircle src={user.photoURL} width="50px" height="50px" alt="Photo" />
        </div>
      );
    }

    else {
      const i = user.displayName.substring(0,1);
      return(
        <div>
          <div className="avatar"> {i} </div>
        </div>
      );

    }
  }

  return(

    <div style={{flexDirection:'row', display:'flex', alignItems:'flex-start', marginBottom:20, width:350}}>

      <div style={dot}>{props.place}</div>

      {renderAvatar(u)}

      <div style={{width:200, marginLeft:10}}>

        <div className="displayName">{u.displayName}</div>

        <div className="homeTown">{u.homeTown}</div>

      </div>

      <div className="gameScore">{props.leader.score}</div>

    </div>
  );
}
