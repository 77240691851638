import React from 'react';
import Image from 'react-bootstrap/Image';
import { Link } from "react-router-dom";
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UserRow from './UserRow';

export default function GameCard(props) {

  const getPlaceText = (place) => {
    let str = place;
    if (place == 1)
      str += "st";
    if (place == 2)
      str += "nd";
    if (place == 3)
      str += "rd";
    else if (place > 3)
      str += "th";
    return str;
  }
  const renderAvatar = (user) => {

    if (user){

      if (user.photoURL){
        return(
          <div className="avatar">
            <Image roundedCircle src={user.photoURL} width="40" height="40" alt="Photo" />
          </div>
        );
      }

      else {
        const i = user.displayName.substring(0,1);
        return(
          <div className="avatar"> {i} </div>
        );

      }

    }

  }
  const getPercentage = () => {

    const gp = props.putts;
    const tot = gp.length;
    let ct = 0;

    for (var i=0;i<gp.length;i++){
      if (gp[i].made == 1){
        ct++;
      }
    }

    const p = Math.round( (ct / tot) * 100);
    return p;

  }
  const renderStat = (num, text) => {

    const boxStyle = {padding:15, backgroundColor:'#eee', borderRadius:10, marginRight:10, marginTop:10};

    return(
      <div style={boxStyle}>
        <div className="gameStat">{num}</div>
        <div className="label">{text}</div>
      </div>
    );
  }
  const renderPuttOverview = () => {

    const tot = props.putts.length;
    const score = props.leaderboard.score;
    const perc = getPercentage();

    return(

      <div className="prow" style={{alignItems:'center'}}>

        {renderStat(score, 'score')}

        {renderStat(tot, 'putts')}

        {renderStat(perc + "%", 'made')}

      </div>

    );
  }
  const renderUserScore = () => {

    const user = props.user;
    const score = props.leaderboard.score;
    const sc = props.scores;
    let place = 1;
    for (var i=0;i< sc.length;i++){
      //compare player score to current leaderboard
      if (score == sc[i].score){
        place = i;
        break;
      }
    }

    const pl = getPlaceText(place+1);

    return(

      <div className="lbRowSelected">

        <div className="lbPlace">{pl}</div>

        {renderAvatar(user)}

        <div className="gameDisplayName">{user.displayName}</div>

        <div className="lbScore">{score}</div>

      </div>

    );
  }
  const renderScores = (scores) => {

    if (scores.length == 0)
      return <div className="title">NO SCORES YET</div>

    let ct = 0;
    let howMany = 3;

    return scores.map((score, index) => {

      if (ct++ < howMany){

        const user = JSON.parse(score.userName);
        const pl = getPlaceText(index+1);

        return(

          <div key={index} className="lbRow">

            <div className="lbPlace">{pl}</div>

            {renderAvatar(user)}

            <div className="lbDisplayName">{user.displayName}</div>

            <div className="lbScore">{score.score}</div>

          </div>

        );

      }

    });
  }

  const lb = props.leaderboard;
  const sc = props.scores;
  const u = props.user;
  const gp = props.putts;

  const m = moment(lb.created_at).fromNow();

  return(

    <Card style={{padding:20}}>

      <div style={{display: 'grid', placeItems: 'center'}}>

        <div className="gameTitle">{lb.title}</div>

        <div className="gameDescription">{lb.description}</div>

        <div style={{marginTop:10}}>

          <UserRow user={u} />

        </div>

        <div className="timeAgo" style={{marginTop:'10px'}}>{m}</div>

        <div style={{marginTop:'10px'}} className="justify-content-start">

          {renderPuttOverview()}

        </div>

      </div>

    </Card>


  );


}
