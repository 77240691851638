import React, { useState } from "react";
import "./TournamentBracket.css";

export default function TournamentBracket () {

  const [players, setPlayers] = useState([
    "Player 1", "Player 2", "Player 3", "Player 4", "Player 5", "Player 6", "Player 7", "Player 8",
    "Player 9", "Player 10", "Player 11", "Player 12", "Player 13", "Player 14", "Player 15", "Player 16",
  ]);
  const [round1Left, setRound1Left] = useState([[0,15,-1],[7,8,-1],[3,12,-1],[4,11,-1]]);
  const [round1Right, setRound1Right] = useState([[1,14,-1],[6,9,-1],[2,13,-1],[5,10,-1]]);
  const [round2Left, setRound2Left] = useState([[],[]]);
  const [round2Right, setRound2Right] = useState([[],[]]);
  const [finals, setFinals] = useState([]);

  const renderRound = (title, side) => {

    return(

      <div className="column">

        <h3>{title}</h3>

        {renderMatches(title, side)}

      </div>

    );
  }
  const handleInputChange = (player, value) => {
    let newArr = [...players];
    newArr[player] = value;
    setPlayers(newArr);
  }
  const renderMatch = (player1, player2, level) => {

    if (level == 1){

      return(

        <div className="match">

          <div className="round1">

            <input
              className="input-underline"
              type="text"
              value={players[player1]}
              onChange={(e) => handleInputChange(player1, e.target.value)}
            />

            <div onClick={() => { pressWinner(player1, 1)}} style={{width:20, height:20, borderRadius:10, backgroundColor:'green'}}>

            </div>

          </div>

          <div style={{padding:10}}>VS</div>

          <div className="round1">

            <input
              className="input-underline"
              type="text"
              value={players[player2]}
              onChange={(e) => handleInputChange(player2, e.target.value)}
            />

            <div onClick={() => { pressWinner(player2, 1)}} style={{width:20, height:20, borderRadius:10, backgroundColor:'green'}}>

            </div>

          </div>

        </div>
      );

    }

    else {

      let p1 = "TBD";
      let p2 = "TBD";
      if (player1)
        p1 = players[player1];
      if (player2)
        p2 = players[player2];
      return(

        <div className="match">

          <div className="round">{p1}</div>

          <div style={{padding:10}}>VS</div>

          <div className="round">{p2}</div>

        </div>
      );
    }


  }
  const renderMatches = (title, side) => {

    if  ( (title == "ROUND 1") && (side == "LEFT") ) {

      return round1Left.map((match, index) => {

        return renderMatch(match[0],match[1],1);

      });

    }
    else if  ( (title == "ROUND 1") && (side == "RIGHT") ) {

      return round1Right.map((match, index) => {

        return renderMatch(match[0],match[1],1);

      });

    }
    else if  ( (title == "ROUND 2") && (side == "LEFT") ) {

      return round2Left.map((match, index) => {

        return renderMatch(match[0],match[1]);

      });

    }
    else if  ( (title == "ROUND 2") && (side == "RIGHT") ) {

      return round2Right.map((match, index) => {

        return renderMatch(match[0],match[1]);

      });

    }
    else if  ( (title == "FINAL") ) {

      return renderMatch(finals[0],finals[1]);

    }

  }
  const pressWinner = (player, round) => {
    console.log("winner:" + player + " , " + round);
  }

  return (
    <div className="tournament-bracket">
      <div className="bracket">
        <div className="column">{renderRound("ROUND 1", "LEFT")}</div>
        <div className="column">{renderRound("ROUND 2", "LEFT")}</div>
        <div className="column">{renderRound("FINAL")}</div>
        <div className="column">{renderRound("ROUND 2", "RIGHT")}</div>
        <div className="column">{renderRound("ROUND 1", "RIGHT")}</div>
      </div>
    </div>
  );

}
