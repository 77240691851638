import Tees from './Tees';
import './GameView.css';
import './Putt.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faBan } from '@fortawesome/free-solid-svg-icons';

import { lightGreen, green, red } from '../../constants/Colors';
import BallPath from './BallPath';
import HoleAngle from './HoleAngle';
import { getHoleEntry, getTeeEntry, getBallSpeed, getMissResult, getEntryDescription, getSpeedDescription, getPuttGrade, getPuttScore } from './GameFunctions';

export default function Putt(props) {

  const hb = JSON.parse(props.putt.holeBounds);
  const bp = JSON.parse(props.putt.ballPath);
  const perfectSpeed = 7;

  const renderMakeDetails = () => {

    const holeEntry = getHoleEntry(JSON.parse(props.putt.ballPath), JSON.parse(props.putt.holeBounds));
    const teeEntry = getTeeEntry(props.putt.distance, props.putt.tee);

    const angDiff = holeEntry[2] - teeEntry;
    let entrySide = "PERFECT";

    //calc score from center cut
    const startScore = 10;
    const degOff = Math.abs(angDiff);
    const es = ((startScore - (degOff / 10))) * 10;

    return(
      <div style={{alignItems:'center'}}>

        <HoleAngle size={50} teeEntry={teeEntry} score={angDiff} holeEntry={holeEntry} angle={20} />

      </div>
    );
  }
  const renderPuttResult = () => {

    if (props.putt.made == 1) {
      return renderMakeDetails();
    }

    return(

      <div style={{width:50, height:50, backgroundColor:red, borderRadius:25, display:'flex', alignItems:'center', justifyContent:'center'}}>

        <FontAwesomeIcon color={'#fff'} icon={ faBan } size="2x" />

      </div>

    );
  }
  const renderPuttDetails = () => {

    let teeText = "STRAIGHT";
    if (props.putt.tee == 1)
      teeText = "LEFT TO RIGHT";
    if (props.putt.tee == 3)
      teeText = "RIGHT TO LEFT";


    const holeEntry = getHoleEntry(bp, hb);
    const teeEntry = getTeeEntry(props.putt.distance, props.putt.tee);
    const speed = getBallSpeed(bp, hb);
    const speedPer = Math.round((speed/perfectSpeed) * 100);

    const speedDescription = getSpeedDescription(speed, perfectSpeed) + " (" + speedPer + "%)";

    let angDiff = holeEntry[2] - teeEntry;
    let entryDescription = getEntryDescription(angDiff) ;

    if (props.putt.made == 0){
      entryDescription = "MISSED";
    }

    let score = getPuttScore(angDiff, props.putt.made, speed, perfectSpeed);

    return(

      <div style={{flexDirection:'row', display:'flex'}}>

        <div>

          <div style={{flexDirection:'row', display:'flex', alignItems:'flex-start'}}>

            <div className="puttData">{props.putt.distance}</div>

            <div className="puttText">footer, </div>

            <div className="puttData">{teeText}</div>

          </div>

          <div style={{flexDirection:'row', display:'flex', alignItems:'flex-start'}}>

            <div className="puttData">{speedDescription}</div>

            <div className="puttText">speed </div>

          </div>

          <div style={{flexDirection:'row', display:'flex', alignItems:'flex-start'}}>

            <div className="puttData">{entryDescription}</div>

            <div className="puttText">entry </div>

          </div>

        </div>

        <div style={{marginLeft:10}}>

          {renderPuttGrade(score)}

        </div>

      </div>
    );
  }
  const renderPuttGrade = (score) => {

    let color = '#fff';
    if (score <= 50){
      color = red;
    }
    else if ( (score > 50) && (score <= 80) ){
      color = green;
    }
    else {
      color = lightGreen;
    }

    let grade = getPuttGrade(score);

    return(

      <div style={{alignItems:'center', justifyContent:'center', textAlign:'center'}}>

        <div style={{fontSize:30, fontWeight:'bold', color:color}}>{grade}</div>

        <div style={{fontSize:12, color:color}}>{Math.round(score)}</div>

      </div>
    );
  }
  let fill = red;
  if (props.putt.made == 1){
    fill = lightGreen;
  }

  let dot = {
    position: 'relative',
    borderColor:'#fff',
    borderStyle:'solid',
    color:'#fff',
    borderWidth: 1,
    left:0,
    top:20,
    width:20,
    height:20,
    borderRadius:10,
    backgroundColor:fill,
    alignItems:'center',
    justifyContent:'center',
    fontWeight:'bold',
    fontSize:'9pt',
    textAlign:'center'
  };
  let num = props.index + 1;

  return (

      <div>

        <div style={dot}>{num}</div>

        <div className="puttCard">

          <div style={{ alignItems:'center', justifyContent:'center', display:'flex', height:80, width:60}}>

            {renderPuttResult()}

          </div>

          <div style={{marginLeft:5}}>

            {renderPuttDetails()}

          </div>

        </div>

      </div>

    );

}
