import { lightGreen, green, red } from '../../constants/Colors';

export default function HoleAngle(props) {


  const renderPuttText = (angle) => {

    const w = props.size;
    const h = props.size;

    let locText = "PERFECT";

    if (props.score > 0){
      locText = "RIGHT";
    }
    else if (props.score < 0){
      locText = "LEFT";
    }

    let angleText = Math.abs(props.score) + String.fromCharCode(176);

    return(

      <>
        <text
          fill={green}
          fontSize={14}
          fontWeight="bold"
          fontFamily="montserrat"
          x={(w/2)}
          y={(h/2) + 5}
          textAnchor="middle"
        >
          {angleText}
        </text>

      </>

    );


  }
  const renderPerfectEntry = (angle) => {
    const per = (angle / 360) * 100;
    const fontSize = props.size / 4;
    const w = props.size;
    const h = props.size;
    const rad = (props.size / 2) - 6;
    const dia = rad*2;
    const circ = rad * (2*3.14);
    const newPer = (circ / 100) * per;
    const d = "M"+(w/2)+" "+((h-dia) / 2)+" a "+rad+" "+rad+" 0 0 1 0 "+dia+" a "+rad+" "+rad+" 0 0 1 0 -"+dia;
    const arr = newPer + ", " + circ;
    let rot = 90;

    if (props.teeEntry){
      rot = rot - props.teeEntry;
    }

    const tr = "rotate("+(rot + (rot - (angle / 2)) )+","+w/2+","+h/2+")";

    return(

      <path
        d={d}
        stroke="#098B54"
        strokeWidth="12"
        strokeDasharray={arr}
        transform={tr}
      />

    );
  }
  const renderEntry = (angle) => {

    const per = (angle / 360) * 100;
    const fontSize = props.size / 4;
    const w = props.size;
    const h = props.size;
    const rad = (props.size / 2) - 6;
    const dia = rad*2;
    const circ = rad * (2*3.14);
    const newPer = (circ / 100) * per;

    const d = "M"+(w/2)+" "+((h-dia) / 2)+" a "+rad+" "+rad+" 0 0 1 0 "+dia+" a "+rad+" "+rad+" 0 0 1 0 -"+dia;
    const arr = newPer + ", " + circ;
    let rot = 90;

    if (props.holeEntry){
      rot = rot - props.holeEntry[2] / 2;
    }


    const hr = "rotate("+(rot + (rot - (angle / 2)) )+","+w/2+","+h/2+")";

    if (props.holeEntry){

      return(

        <path
          d={d}
          stroke={"white"}
          strokeWidth="12"
          strokeDasharray={arr}
          transform={hr}
        />

      );

    }

  }
  const renderCircle = (angle) => {

    const per = (angle / 360) * 100;
    const fontSize = props.size / 4;
    const w = props.size;
    const h = props.size;
    const rad = (props.size / 2) - 5;
    const dia = rad*2;
    const circ = rad * (2*3.14);
    const newPer = (circ / 100) * per;
    const d = "M"+(w/2)+" "+((h-dia) / 2)+" a "+rad+" "+rad+" 0 0 1 0 "+dia+" a "+rad+" "+rad+" 0 0 1 0 -"+dia;
    const arr = newPer + ", " + circ;
    let rot = 90;

    if (props.teeEntry){
      rot = rot - props.teeEntry;
    }

    const tr = "rotate("+(rot + (rot - (angle / 2)) )+","+w/2+","+h/2+")";

    return(

      <div>

        <svg height={w} width={h}>

          <path
            d={d}
            stroke='#000'
            strokeWidth="10"
            fill='#000'
          />

          {renderEntry(30)}

          {renderPerfectEntry(10)}

          <circle cx={w/2} cy={h/2} r={rad} fill={lightGreen} />

          {renderPuttText()}

        </svg>

      </div>
    );
  }


  return renderCircle(props.angle);


}
